import { agreementsModel } from './agreementsModel';
import RPC from "@/shared/api/RPC";
import application from '@/app/store/modules/application';

const customFilterAgreement = (agreements) => {
    return agreements.reduce((acc, agreement) => {
        if (agreement.type === 'checkbox' && agreement.code === 'insurance') {
            return acc;
        }

        if (agreement.type === 'checkbox' && agreement.code === 'insurance_med') {
            return acc;
        }

        if (agreement.type === 'text' && ['insurance_work_v2', 'insurance_work_request'].includes(agreement.code)) {
            if (agreementsModel.state.agreementsMap['insurance_work_v2'] === false) {
                return acc;
            }
        }

        if (agreement.type === 'text' && ['insurance_request', 'insurance'].includes(agreement.code)) {
            if (agreementsModel.state.agreementsMap['insurance'] === false) {
                return acc;
            }
        }

        if (agreement.type === 'text' && ['insurance_med', 'insurance_med_request'].includes(agreement.code)) {
            if (agreementsModel.state.agreementsMap['insurance_med'] === false) {
                return acc;
            }
        }

        if (agreement?.children?.length > 0) {
            const buildedChildren = customFilterAgreement(agreement?.children);
            return [
                ...acc,
                {
                    ...agreement,
                    children: buildedChildren
                }
            ];
        }

        return [...acc, agreement];
    }, []);

};

const customEventAgreement = ({ code, value }) => {
    switch (code) {
        case 'insurance_med':
        case 'assignment_of_claims':
        case 'insurance':
        case 'insurance_work_v2': {
            RPC({
                method: 'updateAdditionalAgreement',
                params: {
                    code,
                    agreement: value,
                    loan_application_id: application.state.loan_application?.id
                }
            })
            return;
        }
        default:
    }
};

export {
    customFilterAgreement,
    customEventAgreement
}
