import captcha from "@/shared/mixins/captcha";

export default captcha.extend({
    computed: {
        application() {
            const application = this.$store.state?.application ?? {}

            if (application?.loan_application == null) {
                return {}
            }

            return application.loan_application;
        },
        incomeInfo() {
            return this.$store.state?.application?.loan_application?.add_income ?? this.$store.state?.application?.loan_application?.borrower?.add_income;
        },
        isRepeater(): boolean {
            return this.application.type === 'repeater'
        },
        isNewCalc(): boolean {
            return this.$store.state?.application?.loan_application?.decision_result?.calc_type === 'new';
        }
    }
})
